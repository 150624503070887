import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import MuseDentalClinic from '../images/muse-dental-clinic.jpg';
import MuseDentalServices from '../images/services-general-dentistry.jpg';
import MuseDentalReception from '../images/muse-clinic-1.jpg';
import MuseDentalOurTeam from '../images/muse-dental-our-team3.jpg';
import DrBanitaMann from '../images/DrBanitaMann-Resize2.jpeg';
import Covid19 from '../images/covid-19.jpg';
import MuseBGVideo from '../images/muse-bg-video.mp4';
import AppointmentCTA from '../components/AppointmentCTA';
import SEO from '../components/Seo';

// markup
const IndexPage = () => {


  return (

   <Layout>
     <SEO title="Muse Dental - Home" description="We're a Modern Dental Practice Located in Delta, BC. Book an Appointment with us to experience a fresh new approach to dentistry."/>
    <div className="relative overflow-hidden">  

      <div className="h-90vh flex flex-col z-10">
        <main className="mx-auto my-auto max-w-7xl px-4">
          <div className="object-cover bg-muse-teal bg-opacity-60 top-0 left-0 w-screen h-screen absolute"> 
            <video autoPlay={true} muted playsInline loop className="object-cover top-0 left-0 w-screen h-screen absolute -z-20" src={MuseBGVideo} poster={MuseDentalReception}></video>
          </div>
          <div className="text-center relative z-10">
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Muse Dental</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-white sm:text-lg md:mt-5 md:text-2xl md:max-w-3xl">
            We're a New Modern Dental Practice Located in Delta, BC. Book an Appointment with us to experience a fresh new approach to dentistry.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <button onClick={() => window.initFlexBook('https://flexbook.me/musedental')} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-muse-yellow hover:bg-muse-teal md:py-4 md:text-lg md:px-10">
                  Book Appointment
                </button>
              </div>
              
            </div>
          </div>
        </main>
      </div>

       

        <section>
          <div className="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="relative max-w-7xl mx-auto">
            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-48 w-full object-cover" src={MuseDentalServices} alt="A girl smiling while receiving dental treatment"/>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                      <p className="text-2xl font-semibold text-gray-900">
                        Our Services
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                      At Muse Dental, we make sure you're taken care of. We have comprehensive selection of services to make sure the entire family has a healthy smile.
                      </p>
                  </div>
                  <div className="mt-6 flex items-center">
                  <Link to="/services" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-muse-yellow hover:bg-muse-teal">
                      View Our Services
                  </Link>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-48 w-full object-cover" src={MuseDentalClinic} alt="Muse Dental Main Window Photo"/>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                      <p className="text-2xl font-semibold text-gray-900">
                        Our Clinic
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        Our clinic is thoroughly modern with the latest dental equipment. We pride ourselves on providing the highest level of patient comfort.
                      </p>
                  </div>
                  <div className="mt-6 flex items-center">
                    <Link to="/clinic" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-muse-yellow hover:bg-muse-teal">
                      Tour Our Clinic
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-48 w-full object-cover" src={DrBanitaMann} alt="Dr. Banita Mann & Dr. Jasdeep Khela"/>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                      <p className="text-2xl font-semibold text-gray-900">
                        Our Team
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                      Dr. Mann's journey began with humble roots in Delta, BC, where she was raised by two hardworking immigrant parents who instilled in her the value of perseverance and education. 
                      </p>
                  </div>
                  <div className="mt-6 flex items-center">
                    <Link to="/team" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-muse-yellow hover:bg-muse-teal">
                      Meet Our Team
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

        <section>
        <div className="relative py-16 bg-white">
          <div className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block" aria-hidden="true"></div>
          <div className="max-w-7xl mx-auto bg-muse-teal lg:bg-transparent lg:px-8">
            <div className="lg:grid lg:grid-cols-12">
              <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
                <div className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true"></div>
                <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                  <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                    <img className="object-cover object-center rounded-3xl shadow-2xl" src={Covid19} alt="A patient wearing a face mask."/>
                  </div>
                </div>
              </div>

              <div className="relative bg-muse-teal lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
                <div className="relative max-w-md mx-auto py-12 px-4 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:py-6 lg:col-start-4 lg:col-span-6">
                  <h2 className="text-3xl font-extrabold text-white" id="join-heading">Our COVID-19 Protocols</h2>
                  <p className="text-lg text-white mt-2">To ensure everyone's safety, we have a set of COVID-19 protocols in place.</p>
          
                  <p className="text-lg text-white font-bold mt-5">Screening Questions</p>
                  <p className="text-base text-white">
                  Patients will receive an email or text 2 hours prior to their appointment with some Covid screening questions. This can be done prior to entering our office to ensure everyone is kept safe.
                  </p>

                  <p className="text-lg text-white font-bold mt-5">Masks</p>
                  <p className="text-base text-white">
                  Everyone will be wearing masks. Our team double masks for every patient. All patients wear masks and remove them once in the operatory. 
                  </p>

                  <p className="text-lg text-white font-bold mt-5">Physical Distancing</p>
                  <p className="text-base text-white">
                  We have limited our number of daily appointments to ensure there is plenty of physical distancing. 
                  </p>

                  <p className="text-lg text-white font-bold mt-5">No Walk-Ins</p>
                  <p className="text-base text-white">
                  We require all patients to call and book appointments, and are currently not accepting walk-in patients. 
                  </p>

                  <p className="text-lg text-white font-bold mt-5">Temperature Screening</p>
                  <p className="text-base text-white">
                  We use a touchless thermometer to take patients' temperature prior to their appointment. 
                  </p>

                  <p className="text-lg text-white font-bold mt-5">Hand Sanitization</p>
                  <p className="text-base text-white">
                  All patient's wash their hands prior to being seated and perform a 1 minute rinse. 
                  </p>

                  <p className="text-lg text-white font-bold mt-5">PPE</p>
                  <p className="text-base text-white">
                  Our dentist and CDA wear gowns, gloves, double masks and face shields while treating our patients. 
                  </p>

                  <p className="text-lg text-white font-bold mt-5">Air Purification</p>
                  <p className="text-base text-white">
                  Each operatory has an iQair unit that filters the air throughout the dental procedure. 
                  </p>

                  <p className="text-lg text-white font-bold mt-5">Enclosed Operatory</p>
                  <p className="text-base text-white">
                  Our operatories are fitted with pocket doors, so as to limit any aerosols from being transferred out of the operatory. 
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        </section>

        <AppointmentCTA/>

      </div>
      </Layout>
     
  )
}

export default IndexPage;
